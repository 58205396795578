import React from 'react';
import { Link } from 'react-router-dom';
import './styles/header.css';

const Header = () => {
    return (
        <header className="header">
            <nav className="nav">
                <ul>
                    <li><Link to="/">Главная</Link></li>
                    <li><Link to="/bots">Боты</Link></li>
                    <li><Link to="/proxy">Прокси</Link></li>
                    <li><Link to="/messages">Сообщения</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;