import React, { useEffect, useState } from 'react';
import axios from 'axios';
import MessagesList from '../components/randomMessages';
import FileUpload from '../components/fileUpload';
import './styles/messagePage.css'

const MessagesPage = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        axios.get('https://vzakat.ru/backend/api/chat/getMessages')
            .then(response => {
                setMessages(response.data);
            })
            .catch(error => {
                console.error("There was an error fetching the messages!", error);
            });
    }, []);

    return (
        <div className="messages-page">
            <div className="messages-container">
                <MessagesList messages={messages} />
            </div>
            <div className="upload-container">
                <FileUpload />
            </div>
        </div>
    );
};

export default MessagesPage;
