import React from 'react';
import './styles/messagesList.css';

const MessagesList = ({ messages }) => {
    return (
        <div className="messages-list">
            {messages.map(message => (
                <div key={message.id} className="message-item">
                    <strong>ID:</strong> {message.id} <br />
                    <strong>Message:</strong> {message.message}
                </div>
            ))}
        </div>
    );
};

export default MessagesList;
