import React from "react";
import "./styles/fullScreenLoader.css";

const FullscreenLoader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="fullscreen-loader">
      <div className="spinner"></div>
    </div>
  );
};

export default FullscreenLoader;
