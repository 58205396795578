import React, { useState } from 'react';
import './styles/proxyList.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FullscreenLoader from './fullScreenLoader';

const ProxyList = ({ proxys }) => {
    const navigate = useNavigate()
    const[isLoading, setLoading] = useState(false)

    const handleDelete = (id) =>{
        setLoading(true)
        axios.delete('https://vzakat.ru/backend/api/proxy/'+id).then(res=>{
            if(res.status === 200){
                setLoading(false)
                navigate(0)
            }
        })
    }

    return (
        <div className="user-list">
            <FullscreenLoader isLoading={isLoading}/>
            {proxys.map(proxy => (
                <div key={proxy.proxy_id} className="proxy-item">
                    <span>{proxy.ip}:{proxy.port}</span>
                    <div className="user-actions">
                        <button className="delete" onClick={() => handleDelete(proxy.proxy_id)}>Удалить</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProxyList;
