import React, { useState } from 'react';
import './styles/fileUpload.css';
import axios from 'axios';

const FileUpload = () => {
    const [dragActive, setDragActive] = useState(false);

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            const file = e.dataTransfer.files[0];
            if (file.type === "text/plain") {
                handleFileUpload(file);
            } else {
                alert("Please upload a valid .txt file");
            }
        }
    };

    const handleFileUpload = (file) => {
        const formData = new FormData();
        formData.append("file", file);

        axios.post("https://vzakat.ru/backend/api/chat/upload", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log("File uploaded successfully", response.data);
        }).catch(error => {
            console.error("Error uploading file", error);
        });
    };

    return (
        <div 
            className={`dropzone ${dragActive ? 'active' : ''}`} 
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
        >
            <p>Перемести .txt файл сюда</p>
        </div>
    );
};

export default FileUpload;
