import { useEffect, useState } from "react";
import BotsList from "../components/botsList";
import Header from "../components/header"
import './styles/bots.css'
import '../components/styles/modal.css'; // Импортируем файл стилей для модального окна
import axios from "axios";
import Modal from "../components/modal";
import { useNavigate } from "react-router-dom";
import FullscreenLoader from "../components/fullScreenLoader";

const Bots = ({}) => {
    const navigate = useNavigate()
    const[isLoading, setLoading] = useState(false)

    function getCurrentDateTimeWithYearOffset(yearsOffset) {
        const now = new Date(); // текущая дата и время
        const futureDate = new Date(now.getFullYear() + yearsOffset, now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds()); // добавляем годы к текущей дате
        
        // форматирование в ISO 8601
        const isoString = futureDate.toISOString();
    
        // возвращаем нужный формат
        return isoString.substring(0, 23) + '+00:00'; // обрезаем миллисекунды и добавляем часовой пояс
    }

    const [bots, setBots] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [newBotData, setNewBotData] = useState({
        username: '',
        password: '',
        authCookie: { cookieName: '', cookieValue: '' }
    });

    useEffect(() => {
        if (bots === null) {
            setLoading(true)
            axios.get("https://vzakat.ru/backend/api/bots").then(res => {
                if (res.status === 200) {
                    setLoading(false)
                    setBots(res.data);
                }
            });
        }
    }, [bots, isLoading]);

    const handleAddBot = () => {
        setShowAddModal(true);
    };

    const handleCloseModal = () => {
        setShowAddModal(false);
        setNewBotData({
            username: '',
            password: '',
            authCookie: { cookieName: '', cookieValue: '' }
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBotData({ ...newBotData, [name]: value });
    };

    const handleCookieChange = (value) => {
        setNewBotData({ ...newBotData, authCookie: {
            cookieName: "auth-token",
            cookieValue: value,
            domain: ".twitch.tv",
            expires: getCurrentDateTimeWithYearOffset(1),
            secure: true,
            path: "/",
            httpOnly: false
        } });
    };

    const handleSaveBot = () => {
        console.log('Saving new bot:', newBotData);
        setLoading(true)
        axios.post("https://vzakat.ru/backend/api/bots", newBotData).then(res=>{
            if(res.status===200){
                setShowAddModal(false);
                setLoading(false)
                navigate(0)
            }
        })
    };
    if(bots!==null){
    return (
        <div>
            <FullscreenLoader isLoading={isLoading}/>
            <button className="add-button" onClick={handleAddBot}>Добавить</button>
            <BotsList bots={bots} />
            <Modal show={showAddModal} onClose={handleCloseModal}>
                <div className="modal-form">
                    <div className="modal-header">
                        <h2>Добавить нового бота</h2>
                    </div>
                    <div className="modal-body">
                        <div>
                            <label>Login:</label>
                            <input
                                type="text"
                                name="username"
                                value={newBotData.username}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label>Password:</label>
                            <input
                                type="password"
                                name="password"
                                value={newBotData.password}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <h3>Авторизационное Cookie:</h3>
                                <div>
                                    <input
                                        type="text"
                                        placeholder="Значение Cookie"
                                        onChange={(e) => handleCookieChange(e.target.value)}
                                    />
                                </div>
                        </div>
                    </div>
                    <div className="modal-actions">
                        <button onClick={handleSaveBot}>Сохранить</button>
                        <button style={{backgroundColor: "#dc3545", color: "white"}} onClick={handleCloseModal}>Отмена</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
};

export default Bots;
