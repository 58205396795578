import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/main';
import Bots from './pages/bots';
import Proxy from './pages/proxy';
import Header from './components/header';
import MessagesPage from './pages/messagePage';

function App() {
  return (
  <Router>
    <Header/>
      <Routes>
          <Route exact path='/' element={<MainPage/>}/>
          <Route exact path='/bots' element={<Bots/>}/>
          <Route exact path='/proxy' element={<Proxy/>}/>
          <Route exact path='/messages' element={<MessagesPage/>}/>
      </Routes>
  </Router>
  );
}

export default App;
